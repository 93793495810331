import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from '@tanstack/react-query';
import {
  Entity,
  GetEndpointResponse,
  EntityDetail,
} from '@web-config-app/core';
import { getEntityEndpoint } from '../../utilities/get-entity-endpoint/get-entity-endpoint.util';

type EntityGetUseQueryResult = UseQueryResult<GetEndpointResponse>;

export type UseEntityGetResult = Omit<EntityGetUseQueryResult, 'data'> & {
  /**
   * There's a clash between `useQuery` returning a `data` property and our endpoints that ALSO include a `data` property
   * in the response. So, to avoid have `data.data`, hoist the endpoint's `response.data` to the top level here.
   */
  data?: EntityDetail;
};

/**
 * useEntityGet - The following hook is responsible for calling the BE API associated to the Get (GET) service of an entity.
 * @param props
 * @param props.entity - the whole {@link Entity} that exposes the needed values including the id, endpoints, etc.
 * @param props.instanceId - the entity ID needed to call the GET api with.
 * @param props.options - optional config object
 * @param props.options.fetchFn - You can pass in your own custom fetch function to be used, instead of the default leagueFetch
 * @returns the result of useQuery, which included the fetching, loading statuses and the data returned.
 */
export interface UseEntityGetOptions extends Partial<UseQueryOptions> {
  fetchFn?: (path: string, options: any) => Promise<Response>;
}
interface UseEntityGetProps {
  entity: Entity;
  instanceId?: string;
  options?: UseEntityGetOptions;
}

export const useEntityGet = ({
  entity,
  instanceId,
  options,
}: UseEntityGetProps): UseEntityGetResult => {
  const {
    id: entityId,
    endpoints: { get },
  } = entity;

  if (!get) {
    throw new Error(`Entity ${entityId} has no defined get endpoint`);
  }
  // the path parameters name value is used in the params prop of endpointFetch
  const entityInstanceIdParam = get.pathParameters?.find(
    (param) => get.path.indexOf(`${param.name}`) > -1,
  );
  const entityInstanceIdParamName = entityInstanceIdParam?.name;

  const { fetchFn, enabled = true, retry } = options ?? {};

  const { endpointFetch } = getEntityEndpoint<GetEndpointResponse>(
    get,
    fetchFn,
  );

  /**
   * Uses the endpoint root URL
   *
   * We rename the data returned by useQuery so we can differentiate the data from our API response also.
   */

  const { data: queryResultData, ...queryResult } = useQuery({
    queryFn: () =>
      endpointFetch({
        params: [{ name: entityInstanceIdParamName!, value: instanceId! }],
      }),
    queryKey: [get.path, instanceId],
    enabled: enabled && Boolean(instanceId),
    retry,
    staleTime: Infinity,
  });

  return {
    ...queryResult,
    data: queryResultData?.data,
  };
};
